import * as React from "react"
import { graphql } from 'gatsby'
import { BlogPostPreview } from "../components/post-preview"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Darren Burns - Blog</title>
        <meta name="description" content="Personal technology blog of software engineer Darren Burns." />
      </Helmet>
      <div>
        {data.allMdx.nodes.map(node => <BlogPostPreview key={node.frontmatter.path} postInfo={node.frontmatter} />)}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query AllPosts {
    allMdx(sort: {fields: [slug], order: DESC}) {
      nodes {
        frontmatter {
          title
          description
          date
          draft
          tags
          path
        }
      }
    }
  }

`
